import React, {useState, useContext} from 'react';
import {useLocation} from 'react-router';
import {NavMenu, Popup} from '@partssourceinc/react-ui-core';
import {Default, Phone} from 'responsive';
import {BrComponent, BrPageContext, BrComponentContext} from '@bloomreach/react-sdk';

import 'less/cms/modellanding.less';

const ModelSections = () => {
    const component = useContext(BrComponentContext);
    const [visibleSectionIndex, setVisibleSectionIndex] = useState(null);

    const renderSection = (section, index) => {
        const {title} = section.getParameters();
        const name = section.model.name;

        return (<div className="model-landing_info_header">
            <div className={`model-landing_info_tab lbl-bold`} onClick={() => setVisibleSectionIndex(index)}>{title}</div>
            {visibleSectionIndex === index ? <Popup hideButtons={true} disableClose={false} show={true} inlineHeader={true} onCancel={() => setVisibleSectionIndex(null)}>
                <BrComponent path={`${name}`} />
            </Popup> : null}
        </div>);
    };

    return component.children.map((section, index) => renderSection(section, index))
};

const ModelLanding = () => {
    const page = useContext(BrPageContext);
    const {pathname} = useLocation();
    const oemName = page.getComponent().getModels() && page.getComponent().getModels().oemName || '';
    const pageTitle = page.getTitle();

    return (<div className="model-landing">
        <NavMenu
            isPDP={true}
            disableStickyMenu={true}
            className="header-nav-menu"
            options={[{label: 'Home', url: ''}, {label: 'Models', url: '/models'}, {label: pageTitle, url: '', selected: true}]} />
        <div className="model-landing_header">
            <h1>{pageTitle} {oemName ? 'by ' + oemName : ''}</h1>
            <h3>{oemName}</h3>
            <a href={pathname + '?_view=all'}>See Replacement Parts</a>
        </div>
        <BrComponent path={'main/top'} />

        <div className={'model-landing_info'}>
            <Default>
                <BrComponent path={'main/middle'} />
            </Default>

            <Phone>
                <BrComponent path={'main/middle'}>
                    <ModelSections />
                </BrComponent>
            </Phone>
        </div>

        <BrComponent path={'main/bottom'} />
    </div>);
}

export default ModelLanding;
