import React, {useContext, useEffect} from 'react';
import {addToCookieIfExclusiveSupplier} from 'utility';
import {NavMenu} from '@partssourceinc/react-ui-core';
import {BrComponent, BrPageContext} from '@bloomreach/react-sdk';
import {useRouteMatch, useLocation} from 'react-router';

const OemShowcase = () => {
    const page = useContext(BrPageContext);
    const pageTitle = page && page.getTitle() || '';
    const match = useRouteMatch('(/site/_cmsinternal)?/brands/:oemName');
    const oemName = match && match.params && match.params.oemName || '';
    let pageLabel = pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);
    const location = useLocation();
    const activeSection = page.isPreview() ? (localStorage.getItem('activeSection') || '0')
        : location && location.state && location.state.activeSection || '0';

    useEffect(() => {
        if (oemName) {
            addToCookieIfExclusiveSupplier(oemName);
        }
    }, []);

    return (<div className="oem-showcase">
        <NavMenu
            isPDP={true}
            disableStickyMenu={true}
            className="header-nav-menu"
            options={[{label: 'Home', url: ''}, {label: 'All OEMs', url: '/brands'}, {label: pageLabel, url: '', selected: true}]} />

        <div className="full-row" style={{position: 'sticky', top: '0px', backgroundColor: '#ffffff', zIndex: '99'}}>
            <BrComponent path={'main/content'} />
        </div>

        <div className="oem-showcase_main-container full-row" style={{overflow: 'hidden'}}>
            <BrComponent path={`main/${activeSection}`} />
        </div>
    </div>);
}

export default OemShowcase;
