import React, {useEffect, useContext} from 'react';
import $ from 'jquery';
import PageMetaData from 'components/PageMetaData';
import {NavMenu} from '@partssourceinc/react-ui-core';

import 'less/cms/cmslandingpage.less';

import {BrComponent, BrPageContext} from '@bloomreach/react-sdk';
import ModelLanding from 'pages/cms/ModelLanding';
import {PageNotFound} from 'pages/cms/PageNotFound';
import OemShowcase from 'pages/cms/OemShowcase';

const CmsLandingPage = (props) => {
    const page = useContext(BrPageContext);
    const {template} = page.getComponent().getParameters();

    useEffect(() => {
        $('body,html').css('-ms-overflow-x', 'hidden');

        return () => {
            $('body,html').css('-ms-overflow-x', 'visible');
        }
    }, [])

    let title = page.getTitle();
    let description;

    return (<div className="cms-landing-page" style={{marginBottom: '100px'}}>
        <PageMetaData
            title={title}
            description={description}
            extend={true}
            canonical={window.location.origin + window.location.pathname}
        >
            {!template || template === '' ? <>
                <BrComponent path={'main/container'} />

                <BrComponent path={'main/top'} />
                <div>
                    <div className="row">
                        <div className="ps-hidden-xs ps-hidden-sm ps-hidden-md col-lg-3 cms-landing-page_bottom-container_left-column">
                            <BrComponent path={'main/left'} />
                        </div>
                        <div className="col-md-12 col-lg-9 cms-landing-page_bottom-container_right-column" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                            <BrComponent path={'main/right'} />
                        </div>
                    </div>
                </div>
            </> : null}

            {(template && template === 'page-not-found') && <PageNotFound />}

            {(template && template === 'oem-showcase') && <OemShowcase />}

            {(template && template === 'model-landing') && <ModelLanding />}

            {template && template === 'landing' ? <>
                <NavMenu
                    className="header-nav-menu"
                    isPDP={true} options={[{label: 'Home', url: ''}, {label: title, url: ''}]} />
                <div style={{marginTop: '50px', marginBottom: '50px'}}><h1>{title}</h1></div>
                <BrComponent path={'main/content'} />
            </> : null}

        </PageMetaData>
    </div>)
}

export default CmsLandingPage;
